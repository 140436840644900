import { Group, Cell, CellButton } from '@vkontakte/vkui';
import React from 'react'

function DesktopNavigation({go}) {
    return (
        <div>
            <Group>
                <CellButton onClick={() => go('create')}>
                    Создать
                </CellButton>
            </Group>
            <Group>
                <Cell onClick={() => go('home')}>
                    Главная
                </Cell>
                <Cell onClick={() => go('quete')}>
                    Все записи
                </Cell>
            </Group>
        </div>
    )
}

export default DesktopNavigation;