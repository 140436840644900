import React from 'react';
import CheckboxAnswer from './CheckboxAnswer';
import RadioAnswer from './RadioAnswer';
import SimpleTextAnswer from './SimpleTextAnswer';
import TextAnswer from './TextAnswer';
function ExtendedAnswer({question_id, question, answers}) {
    console.log(question);
    if (question.answer.type==='simpletext'){
        return (
            <SimpleTextAnswer question={question} question_id={question_id} answers={answers} />
        )
    } else if (question.answer.type === 'text') {
        return (
            <TextAnswer question={question} question_id={question_id} answers={answers} />
        )
    } else if (question.answer.type==="radio") {
        return (
            <RadioAnswer question={question} question_id={question_id} answers={answers} />
        )
    } else if (question.answer.type==="checkbox") {
        return (
            <CheckboxAnswer question={question} question_id={question_id} answers={answers} />
        )
    } else {
        return null
    }

}

export default ExtendedAnswer