import { Button, CellButton, FormItem, Group, Input, Panel, Select } from '@vkontakte/vkui';
import React, { useState } from 'react'
import { Icon24AddOutline } from '@vkontakte/icons';
import QuestionCreate from '../SimpleComponents/QuestionCreate'
import { getRandomInt } from '@vkontakte/vkjs';
function QueteCreate({id}) {
    const [questions, setQuestions] = useState([(
        <Group key={getRandomInt(1, 600)}>
            <QuestionCreate />
        </Group>)])
    let addQuestion = () => {
        console.log("here");
        setQuestions([(
        <Group key={getRandomInt(1, 600)}>
            <QuestionCreate />
        </Group>), ...questions])
    }
    return (
        <Panel id={id}>
            <Group>
                <FormItem title='Название'>
                    <Input type='text'/>
                </FormItem>
            </Group>
            {questions}
            <Group>
                <CellButton onClick={addQuestion} before={<Icon24AddOutline />} size='l'>Добавить</CellButton>
            </Group>

        </Panel>
    )
}

export default QueteCreate;