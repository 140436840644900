import { Checkbox, FormItem } from '@vkontakte/vkui';
import React, { useState, useEffect } from 'react';
import Question from '../SimpleComponents/Qustion';

function CheckboxAnswer({question, question_id, answers}) {
    let options = question.answer.options
    let checks = {} 
    Object.keys(options).forEach(id => {
        checks[id] = false
    })
    const [activeChecks, setActiveChecks] = useState(checks)
    const onChange = e => {
        let checks = activeChecks
        checks[e.currentTarget.dataset.id] = checks[e.currentTarget.dataset.id] ? false : true 
        setActiveChecks(checks)
        answers.setAnswer(question_id, JSON.stringify(checks))
        console.log(activeChecks);
    }
    let buttons = []
    Object.keys(options).forEach(id => {
        buttons.push(<Checkbox key={id} onChange={onChange} data-id={id}> {options[id] + ' ' + activeChecks[id]} </Checkbox>)
    })

    return (
        <Question text={question.title} subtext={question.description}>
            <FormItem>
                {buttons}
            </FormItem>
            
        </Question>
    )
}

export default CheckboxAnswer