import React, { useState } from 'react';
import { View, AdaptivityProvider, AppRoot, Panel, SplitLayout, SplitCol, Group, Cell, ConfigProvider } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import Quete from './Panels/Quete';
import QueteSet from './Panels/QueteSet';
import DesktopNavigation from './Navigation/DesktopNavigation';
import QueteCreate from './Panels/QueteCreate';
const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const go = e => {
		setActivePanel(e);
	};

	return (
		<AdaptivityProvider>
			<ConfigProvider platfom='vkcom'>
				<AppRoot>
					<SplitLayout style={{ justifyContent: "center" }}>
						<SplitCol fixed width={280} maxWidth={280}>
							<DesktopNavigation go={go} />
						</SplitCol>
						<SplitCol 
						width={"560px"}
						maxWidth={"560px"}
					
					>
							<View activePanel={activePanel} >
								<Quete id='quete' />
								<QueteSet id='home' />
								<QueteCreate id='create' />
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</ConfigProvider>
		</AdaptivityProvider>
	);
}

export default App;
