import React, { useState } from 'react'
import {
    FormItem,
    Input,
    Select,
    Textarea,
    Div,
    SplitLayout,
    SplitCol,
    IconButton
} from '@vkontakte/vkui'
import { Icon56DeleteOutline } from '@vkontakte/icons';
function QuestionCreate({id}) {
    const [type, setType] = useState('simpletext')
    const onChange = e => {
        setType(e.currentTarget.value)
    }
    return (
        <div>
            <FormItem>
                <SplitLayout>
                    <SplitCol width={"90%"} maxWidth={"90%"}>
                        <Input />
                    </SplitCol>
                    <SplitCol >
                        <IconButton><Icon56DeleteOutline height={18}/></IconButton>
                    </SplitCol>
                </SplitLayout>
            </FormItem>
            <FormItem>
                <Textarea />
            </FormItem>
            
            <FormItem>
                <Select onChange={onChange} value={type} options={
                    [{value:"simpletext",label:'Текст'}, 
                    {value:"radio",label:"Один вариант ответа"},
                    {value:"text", label: "Текст на несколько абзацев"},
                    {value:"checkbox", label: "Несколько вариантов ответа"}]} >
                </Select>
            </FormItem>
        </div>

    )
}

export default QuestionCreate