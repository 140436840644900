import { FormItem, Textarea } from '@vkontakte/vkui';
import React, { useState } from 'react';
import Question from '../SimpleComponents/Qustion';
import validateOne from '../Utils/TextValidator';
function TextAnswer({question, question_id, answers}) {
    let textRef = React.createRef()
    const [valid, setValid] = useState('default')
    const onChange = (e) => {
        setValid(validateOne(textRef.current.value, question.answer.validations) ? "default" : "error")
        answers.setAnswer(question_id, textRef.current.value)
    }
    return (
        <Question text={question.title} subtext={question.description}>
            <FormItem status={valid} >
                <Textarea 
                        getRef={textRef}
                        type="text" 
                        data-id={question_id}
                        onChange={onChange}
                    />
            </FormItem>
        </Question>
    )
}

export default TextAnswer;