import React, { useState } from 'react';
import { Radio, RadioGroup } from '@vkontakte/vkui'
import Question from '../SimpleComponents/Qustion';

function RadioAnswer({question, question_id, answers}) {
    const [activeRadio, setActiveRadio] = useState('')
    const onChange = e => {
        setActiveRadio(e.currentTarget.dataset.id)
        answers.setAnswer(question_id, e.currentTarget.dataset.id)
    }
    let options = question.answer.options
    let buttons = []
    Object.keys(options).forEach(id => {
        buttons.push(<Radio key={id} onChange={onChange} checked={activeRadio==id} data-id={id}> {options[id]} </Radio>)
    })
    return (
        <Question text={question.title} subtext={question.description}>
            <RadioGroup>
                {buttons}
            </RadioGroup>
        </Question>
    )
}
export default RadioAnswer
