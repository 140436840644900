import { Button, RichCell } from '@vkontakte/vkui';
import React from 'react'

function QueteCard({title}) {
    return (
        <RichCell after={<Button>Начать</Button>}>
            {title}
        </RichCell>
    )

}

export default QueteCard;