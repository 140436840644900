import { Panel, Group, CellButton, Button, PanelHeader } from "@vkontakte/vkui";
import React from "react";
import ExtendedAnswer from "../Answers/ExendedAnswer";
import {WrapAnswers} from '../Utils/WrapAnswers'
function Quete({id}) {
    const questions = {
        '1': {"title":"Email","description":"Введите адрес электронной почты", answer: {type: "simpletext", needValidation: true, validations: ['Email']}},
        '2': {"title":"Age","description":"", answer: {type:"radio",needValidation: false, validations: [], count:3, options: {1:"12-15", 2:"16-19", 3:"20-25"}}},
        '3': {"title":"Name", "description":"", answer: {type:"simpletext", needValidation: false, validations: []}},
        '4': {"title":"Last Name", "description":"", answer: {type:"simpletext", needValidation: false, validations: []}},
        '5': {"title":"Something about you", "description":"", answer: {type:"text", needValidation: false, validations: []}},
        '6': {"title":"Interests", "description":"", answer: {type:"checkbox",needValidation: false, validations: [], count:3, options: {1:"Music", 2:"Art", 3:"Games"}}}
    } //demo
    const sendResult = () => {
        console.log(answers.getAnswers())
    }
    let answers = new WrapAnswers(Object.keys(questions)) 

    return (
        <Panel id={id}>
            <PanelHeader>Кто ты?</PanelHeader>
            <Group>
                {Object.keys(questions).map(id => <ExtendedAnswer key={id} question={questions[id]} question_id={id} answers={answers} />)}
                <Button type="primary" size="l" stretched onClick={sendResult}>Отправить</Button>
            </Group>
        </Panel>
    )
}

export default Quete;