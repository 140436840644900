import validator from 'validator'

function validateOne(string, params) {
    let results = []
    params.forEach(p => {
        switch (p) {
            case 'Email':
                results.push(validator.isEmail(string))
                break;
            case 'Phone':
                results.push(validator.isMobilePhone(string))
                break;
            case 'Age':
                results.push(validator.isNumeric(string) && string.length < 3)
                break;
            case 'Required':
                results.push(string !== "")
                break;
            default:
                break;
        }
    })
    if (!params.length){
        return true
    }
    if (results.indexOf(false) < 0) {
        return true
    } else {
        return false
    }
}

export default validateOne