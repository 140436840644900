import { Div, Group, Text } from '@vkontakte/vkui';
import React from 'react'

function Question({text, children, subtext=""}) {
    return (
        <Group>
            <Div style={{paddingBottom:"0px"}}>
                <div style={{marginBottom:"8px"}}>
                <Text><b>{text}</b></Text>
                <Text>{subtext}</Text>
                </div>
            </Div>
                {children}
            
            
        </Group>
    )
}

export default Question;