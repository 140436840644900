export class WrapAnswers {
    
    constructor (questions_ids, validators={}){
        this.answers = {}
        this.validators = validators
        questions_ids.forEach(e => this.answers[e] = "");
    }
/*
    validate(question_id){
        if (this.validators[question_id]){
            console.log(this.validators[question_id].map(validator => validator(this.answers[question_id])))
            if (this.validators[question_id].map(validator => validator(this.answers[question_id])).indexOf(false) < 0) {
                return true
            } else{
                return false
            }
        } else {
            return true
        }
    }
*/
    setAnswer(question_id, answer){
        this.answers[question_id] = answer
    }

    getAnswers() {
        return this.answers
    }
}


