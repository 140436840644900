import React from 'react';
import { Panel, Group } from '@vkontakte/vkui'
import QueteCard from '../SimpleComponents/QueteCard';
function QueteSet({id}) {
    return (
        <Panel id={id}>
            <Group>
                <QueteCard title="тестовый кьют"/>
            </Group>
        </Panel>
    )
}

export default QueteSet;